<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="width"
    :closeOnClickModal="false"
  >
    <div class="alert--msg_box">
      <div class="msg" v-for="(item, index) in msg" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="alert--footer" v-if="!showFooter">
      <div>
        <el-button size="medium" @click="cancel">取 消</el-button>
      </div>
      <div>
        <el-button size="medium" type="primary" @click="confirm"
          >确 定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    showFooter: {
      default: false,
      type: Boolean
    },
    closeOnClickModal: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      // 是否显示confirm弹窗
      dialogVisible: false,
      // 宽度
      width: '',
      // 标题
      title: '',
      // 消息
      msg: [],
      // 临时存储要删除的信息
      info: ''
      // 临时存储操作类型, 避免页面多次引用组件
    }
  },
  methods: {
    // 显示弹窗
    alert(config = {}, info, type) {
      this.type = type
      this.info = info
      const {
        title = '温馨提醒',
        width = '600px',
        msg = '这个家伙很懒, 什么都没有留下!'
      } = config
      this.title = title
      this.width = width
      // 处理msg
      const msgType = Object.prototype.toString.call(msg)
      if (msgType === '[object String]') {
        this.msg = [msg]
      } else if (msgType === '[object Array]') {
        this.msg = msg
      }
      this.dialogVisible = true
    },

    // 点击取消
    cancel() {
      this.dialogVisible = false
    },

    // 点击了确定
    confirm() {
      this.dialogVisible = false
      this.$emit('confirm', { type: this.type, info: this.info })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert--msg_box {
  padding: 50px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .msg {
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #12203e;
    line-height: 1.8;
  }
}

.alert--footer {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 35px;
  }
}
</style>
