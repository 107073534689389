import http from '@/utils/request.js'

// @登录
export const LoginApi = data => {
  return http({
    url: '/api_air/user/login',
    method: 'post',
    data
  })
}

// @修改密码
export const EditPwdApi = (data = {}) => {
  return http({
    url: '/api_air/user/update_pwd',
    method: 'post',
    data
  })
}

// @获取用户信息
export const GetUserInfoApi = (data = {}) => {
  return http({
    url: '/api_air/user/get_info',
    method: 'get',
    data
  })
}

// @获取运营商
export const GetOperatorListApi = (data = {}) => {
  return http({
    url: '/api_air/operator/get_list',
    method: 'get',
    data
  })
}

// @添加运营商
export const AddOperatorApi = (data = {}) => {
  return http({
    url: '/api_air/operator/add',
    method: 'post',
    data
  })
}

// @修改运营商
export const EditOperatorApi = (data = {}) => {
  return http({
    url: '/api_air/operator/update',
    method: 'post',
    data
  })
}

// @删除运营商
export const DelOperatorApi = (data = {}) => {
  return http({
    url: '/api_air/operator/del',
    method: 'post',
    data
  })
}

// @获取项目
export const GetProjectApi = (data = {}) => {
  return http({
    url: '/api_air/project/get_list',
    method: 'get',
    data
  })
}

// @获取楼栋列表
export const GetBuildApi = (data = {}) => {
  return http({
    url: '/api_air/project/get_building_list',
    method: 'get',
    data
  })
}

// @添加房间
export const AddDormApi = (data = {}) => {
  return http({
    url: '/api_air/project/add_room',
    method: 'post',
    data
  })
}

// @添加项目
export const AddProjectApi = (data = {}) => {
  return http({
    url: '/api_air/project/add',
    method: 'post',
    data
  })
}

// @删除项目
export const DelProjectApi = (data = {}) => {
  return http({
    url: '/api_air/project/del',
    method: 'post',
    data
  })
}

// @更新项目
export const UpdateProjectApi = (data = {}) => {
  return http({
    url: '/api_air/project/update',
    method: 'post',
    data
  })
}

// @添加楼栋
export const AddBuildApi = (data = {}) => {
  return http({
    url: '/api_air/project/add_building',
    method: 'post',
    data
  })
}

// @修改楼栋
export const EditBuildApi = (data = {}) => {
  return http({
    url: '/api_air/project/update_building',
    method: 'post',
    data
  })
}

// @删除楼栋
export const DelBuildApi = (data = {}) => {
  return http({
    url: '/api_air/project/del_building',
    method: 'post',
    data
  })
}

// @获取用户列表
export const GetUserListApi = (data = {}) => {
  return http({
    url: '/api_air/user/get_list',
    method: 'get',
    data
  })
}

// @添加账号
export const AddAccountApi = (data = {}) => {
  return http({
    url: '/api_air/user/add',
    method: 'post',
    data
  })
}

// @获取设备列表
export const GetImeiListApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_imei_list',
    method: 'get',
    data
  })
}

// @分配运营商
export const DistributionApi = (data = {}) => {
  return http({
    url: '/api_air/device/set_imei_operator',
    method: 'post',
    data
  })
}

// @获取未配置设备列表
export const GetWaitConfigListApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_list',
    method: 'get',
    data
  })
}

// @添加未配制设备
export const AddWaitConfigDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/device/add',
    method: 'post',
    data
  })
}

// @获取房间列表
export const GetRoomListApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_room_list',
    method: 'get',
    data
  })
}

// @删除虚拟设备
export const DelDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/device/del',
    method: 'post',
    data
  })
}

// @修改设备
export const EditDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/device/update',
    method: 'post',
    data
  })
}

// @设备配对
export const PairDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/device/pair',
    method: 'post',
    data
  })
}

// @获取真实设备
export const GetRealDeviceListApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_real_list',
    method: 'get',
    data
  })
}

// @获取楼层
export const GetFloorApi = (data = {}) => {
  return http({
    url: '/api_air/project/get_floor',
    method: 'get',
    data
  })
}

// @初始化设备
export const ResetDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/device/reset',
    method: 'post',
    data
  })
}

// @获取设备信息
export const GetDeviceInfoApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_real_info',
    method: 'get',
    data
  })
}

// @操作设备
export const ControlDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/control_device',
    method: 'post',
    data
  })
}

// @锁定空调
export const LockedDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/lock_device',
    method: 'post',
    data
  })
}

// @解锁空调
export const UnlockedDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/unlock_device',
    method: 'post',
    data
  })
}

// @报修工单
export const GetAdviceListApi = (data = {}) => {
  return http({
    url: '/api_air/advice/get_list',
    method: 'get',
    data
  })
}

// @处理意见
export const HandleAdviceApi = (data = {}) => {
  return http({
    url: '/api_air/advice/handle',
    method: 'post',
    data
  })
}

// @获取日志
export const GetLogListApi = (data = {}) => {
  return http({
    url: '/api_air/advice/get_log_list',
    method: 'get',
    data
  })
}

// @获取没有管理员的楼栋
export const GetNoAdminBuildListApi = (data = {}) => {
  return http({
    url: '/api_air/user/get_build',
    method: 'get',
    data
  })
}

// @删除账号
export const DelAccountApi = (data = {}) => {
  return http({
    url: '/api_air/user/del',
    method: 'post',
    data
  })
}

// @更新账号
export const UpdateAccountApi = (data = {}) => {
  return http({
    url: '/api_air/user/update',
    method: 'post',
    data
  })
}

// 获取设备统计
export const GetDeviceCountApi = data => {
  return http({
    url: '/api_air/device/get_real_cnt',
    method: 'get',
    data
  })
}

// @批量操作设备
export const ControlSomeDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/control_device_list',
    method: 'post',
    data
  })
}

// @批量锁定设备
export const LockSomeDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/lock_device_list',
    method: 'post',
    data
  })
}

// @批量空调解锁
export const UnlockSomeDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/control/unlock_device_list',
    method: 'post',
    data
  })
}

// @获取规则列表
export const GetRuleListApi = (data = {}) => {
  return http({
    url: '/api_air/task/get_list',
    method: 'get',
    data
  })
}

// @添加规则
export const AddRuleApi = (data = {}) => {
  return http({
    url: '/api_air/task/add',
    method: 'post',
    data
  })
}

// @修改规则
export const UpdateRuleApi = (data = {}) => {
  return http({
    url: '/api_air/task/update',
    method: 'post',
    data
  })
}

// @删除规则
export const DelRuleApi = (data = {}) => {
  return http({
    url: '/api_air/task/del',
    method: 'post',
    data
  })
}

// @获取空调运行数量
export const GetDeviceRunCountApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_run_device_cnt',
    method: 'get',
    data
  })
}

// @获取空调运行时长
export const GetDeviceRunTimeApi = (data = {}) => {
  return http({
    url: '/api_air/device/get_build_time',
    method: 'get',
    data
  })
}

// @获取程序分组信息
export const GetProgramGroupApi = (data = {}) => {
  return http({
    url: '/api_air/task/get_program_list',
    method: 'get',
    data
  })
}

// @获取规则下面的设备
export const GetRuleCtrlDeviceApi = (data = {}) => {
  return http({
    url: '/api_air/task/get_task_device_list',
    method: 'get',
    data
  })
}

// @添加程序
export const AddProgramApi = (data = {}) => {
  return http({
    url: '/api_air/task/add_program',
    method: 'post',
    data
  })
}

// @删除程序
export const DelProgramApi = (data = {}) => {
  return http({
    url: '/api_air/task/del_program_time',
    method: 'post',
    data
  })
}

// @更新程序
export const UpdateProgramApi = (data = {}) => {
  return http({
    url: '/api_air/task/update_program_time',
    method: 'post',
    data
  })
}

// @删除规则适应的设备
export const DelDeviceRuleApi = (data = {}) => {
  return http({
    url: '/api_air/task/del_task_device',
    method: 'post',
    data
  })
}

// @获取树形结构设备列表
export const GetTreeDeviceListApi = (data = {}) => {
  return http({
    url: '/api_air/task/get_no_task_device_list',
    method: 'get',
    data
  })
}

// @批量添加设备到规则
export const AddDevice2RuleApi = (data = {}) => {
  return http({
    url: '/api_air/task/add_task_device_list',
    method: 'post',
    data
  })
}

// @设置项目温度
export const SetProjectTempApi = (data = {}) => {
  return http({
    url: '/api_air/control/control_project_temp',
    method: 'post',
    data
  })
}

// @设置设备温度
export const SetDeviceTempApi = (data = {}) => {
  return http({
    url: '/api_air/control/control_device_temp',
    method: 'post',
    data
  })
}
