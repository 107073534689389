export default {
  namespaced: true,
  state: state => ({
    // 渲染tagView
    tagViews: [],
    // 缓存的路由
    cacheView: []
  }),
  mutations: {
    // 添加标签
    addTagView(state, view) {
      // 没有名字的路由不会被缓存
      if (!view.meta.name) return
      // 路由中声明了不添加tag标签
      if (view.meta.notag) return
      // 当前标签中已经存在了该路由
      const index = state.tagViews.findIndex(i => i.path === view.path)
      if (index !== -1) {
        state.tagViews.splice(index, 1, view)
        return
      }
      // 正常添加
      state.tagViews.push(view)
    },
    // 添加缓存路由
    addCacheView(state, view) {
      // 没有名字的路由不缓存
      if (view.name === undefined) {
        return
      }
      // 路由中声明不缓存路由
      if (view.meta.nocache) return
      // 当前缓存路由中已经存在该路由
      if (state.cacheView.findIndex(i => i === view.name) !== -1) {
        return
      }
      // 添加缓存路由
      state.cacheView.push(view.name)
    },

    // 删除当前的tag标签
    delCurrentTag(state, view) {
      const index = state.tagViews.findIndex(t => t.fullPath === view.fullPath)
      if (index !== -1) {
        state.tagViews.splice(index, 1)
      }
    },

    // 删除当前标签对应的缓存路由
    delCurrentCacheView(state, view) {
      const index = state.cacheView.findIndex(i => i === view.name)
      if (index !== -1) {
        state.cacheView.splice(index, 1)
      }
    }
  },
  actions: {
    // 添加路由 view 路由对象
    addView({ commit, dispatch }, view) {
      commit('addTagView', view)
      commit('addCacheView', view)
    },

    // 删除当前的路由
    delCurrentView({ commit, state }, view) {
      return new Promise((resolve, reject) => {
        commit('delCurrentTag', view)
        commit('delCurrentCacheView', view)
        resolve({
          tagViews: [...state.tagViews]
        })
      })
    },

    // 删除其他的标签页
    delOthers({ commit, state }, view) {
      return new Promise((resolve, reject) => {
        const viewList = []
        state.tagViews.map(i => {
          viewList.push(i.fullPath)
        })
        viewList.map(i => {
          if (i !== view.fullPath) {
            commit('delCurrentTag', { fullPath: i })
            commit('delCurrentCacheView', { fullPath: i })
          }
        })
        resolve(state.tagViews)
      })
    },

    // 删除所有
    delAll({ commit, state }) {
      return new Promise((resolve, reject) => {
        state.tagViews = []
        state.cacheView = []
        resolve()
      })
    },

    // 重定向当前页
    redirectCurrent({ commit, state }, view) {
      return new Promise((resolve, reject) => {
        commit('delCurrentCacheView', view)
        resolve()
      })
    }
  }
}
