import { GetUserInfoApi } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    // 认证
    token: '',
    // 用户信息
    userInfo: '',
    // 用户role 100超级管理员 1运营商管理员 2项目管理员 3楼栋管理员
    level: ''
  }),
  mutations: {
    // 本地缓存token
    saveToken(state, token) {
      state.token = token
    },

    // 缓存用户信息
    saveUserInfo(state, info) {
      state.userInfo = info
      state.level = info.role
    },

    // 清除登录痕迹(token,userInfo)
    clearLogin(state) {
      state.token = ''
      state.userInfo = ''
    }
  },
  actions: {
    // 缓存token
    saveToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        sessionStorage.setItem('token', data.token)
        commit('saveToken', data.token)
        resolve()
      })
    },

    // 获取token
    getToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        let token = state.token
        if (!token) {
          token = sessionStorage.getItem('token')
          commit('saveToken', token)
        }
        if (token) {
          resolve(token)
        }
        resolve(null)
      })
    },

    // 获取用户信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        GetUserInfoApi()
          .then(res => {
            if (res.ret === 0) {
              commit('saveUserInfo', res.data)
            }
            resolve(res)
          })
          .catch(() => {
            reject(
              new Error({
                ret: -1,
                message: '请求错误'
              })
            )
          })
      })
    },

    // 退出登录
    logout({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        sessionStorage.clear()
        commit('clearLogin')
        dispatch('tagViews/delAll', {}, { root: true })
        resolve()
      })
    }
  }
}
