<template>
  <div class="aside">
    <!-- 二级微logo -->
    <div class="vlogo">蜗牛智享</div>
    <!-- 递归遍历的菜单 -->
    <el-scrollbar class="menu_box">
      <Menu />
    </el-scrollbar>
  </div>
</template>

<script>
import Menu from './Menu.vue'
export default {
  components: { Menu }
}
</script>

<style lang='scss' scoped>
.aside {
  height: 100%;

  .vlogo {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: Alibaba;
    font-weight: normal;
    color: #50e399;
  }

  .menu_box {
    width: 100%;
    height: calc(100% - 76px);
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.aside {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

  .el-menu {
    border-right: none !important;
  }
}
</style>
