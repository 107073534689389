import axios from 'axios'

import { Message } from 'element-ui'

import NProgress from 'nprogress'

import Store from '@/store'

// 测试环境
// axios.defaults.baseURL = 'http://test.ssmanage.com'
axios.defaults.baseURL = 'https://api.ssmanage.com'

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.defaults.timeout = 15000

axios.interceptors.request.use(config => {
  return config
})

axios.interceptors.response.use(config => {
  return config.data
})

export default function({ url, method = 'get', data }) {
  const token = Store.state.user.token
  axios.defaults.headers.Authorization = token || ''
  const query = {
    url,
    method
  }

  if (method.toLowerCase() === 'get') {
    query.params = data
  } else {
    query.data = data
  }

  return new Promise((resolve, reject) => {
    NProgress.start()
    axios(query)
      .then(success => {
        NProgress.done()
        resolve(success)
      })
      .catch(err => {
        NProgress.done()
        resolve({
          ret: -1,
          msg: err.message || '网络一不小心走丢了~',
          data: {}
        })
      })
  })
}
