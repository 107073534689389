import Layout from '@/components/layout/index.vue'
export default [
  /**
   * 关于异步路由命名规则
   * meta中申明 nocache: true, 该路由不会被缓存
   * meta中申明 notag: true, 该路由不会添加 tagView
   * meta中没有申明 name 属性的不会添加 tagView
   * meta中申明 hidden: true 的路由不会显示
   */
  // 数据台
  {
    path: '/data_center',
    component: Layout,
    meta: {
      name: '数据台',
      icon: 'icon-5'
    },
    children: [
      {
        path: '/data_center/data_board',
        name: 'data_board',
        component: () =>
          import('@/views/data_center/data_board/data_board.vue'),
        meta: {
          name: '数据台'
        }
      },
      {
        path: '/data_center/err_device',
        name: 'err_device',
        component: () =>
          import('@/views/data_center/err_device/err_device.vue'),
        meta: {
          name: '异常设备',
          hidden: true
        }
      }
    ]
  },

  // 空调管理
  {
    path: '/device',
    component: Layout,
    meta: {
      name: '空调管理',
      icon: 'icon-7'
    },
    children: [
      {
        path: '/device/device_list',
        name: 'device_list',
        component: () => import('@/views/device/device_list/device_list.vue'),
        meta: {
          name: '空调列表'
        }
      },
      {
        path: '/device/device_view',
        name: 'device_view',
        component: () => import('@/views/device/device_view/device_view.vue'),
        meta: {
          name: '空调视图'
        }
      },
      {
        path: '/device/imei',
        name: 'imei',
        component: () => import('@/views/device/imei/imei.vue'),
        meta: {
          name: 'IMEI列表'
        }
      },
      {
        path: '/device/device_control',
        component: () =>
          import('@/views/device/device_control/device_control.vue'),
        meta: {
          name: '设备控制',
          hidden: true
        }
      }
    ]
  },

  // 智控管理
  {
    path: '/ctrl_rule',
    component: Layout,
    meta: {
      name: '智控管理',
      icon: 'icon-uniE908'
    },
    children: [
      {
        path: '/ctrl_rule',
        name: 'ctrl_rule',
        component: () => import('@/views/ctrl_rule/ctrl_rule.vue'),
        meta: {
          name: '智控管理'
        }
      }
    ]
  },

  // 报修工单
  {
    path: '/repair',
    component: Layout,
    meta: {
      name: '报修工单',
      icon: 'icon-6'
    },
    children: [
      {
        path: '/repair',
        name: 'repair',
        component: () => import('@/views/repair/repair.vue'),
        meta: {
          name: '报修工单'
        }
      }
    ]
  },

  // 运营商管理
  {
    path: '/operator',
    component: Layout,
    meta: {
      name: '运营商管理',
      icon: 'icon-2'
    },
    children: [
      {
        path: '/operator',
        name: 'operator',
        component: () => import('@/views/operator/operator.vue'),
        meta: {
          name: '运营商管理'
        }
      }
    ]
  },

  // 项目管理
  {
    path: '/project',
    component: Layout,
    meta: {
      name: '项目管理',
      icon: 'icon-3'
    },
    children: [
      {
        path: '/project',
        component: () => import('@/views/project/project.vue'),
        name: 'project',
        meta: {
          name: '项目管理'
        }
      }
    ]
  },

  // 账号管理
  {
    path: '/account',
    component: Layout,
    meta: {
      name: '账号管理',
      icon: 'icon-8'
    },
    children: [
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/account.vue'),
        meta: {
          name: '账号管理'
        }
      }
    ]
  },

  // 系统日志
  {
    path: '/system_log',
    meta: {
      name: '系统日志',
      icon: 'icon-4'
    },
    component: Layout,
    children: [
      {
        path: '/system_log',
        name: 'system_log',
        component: () => import('@/views/system_log/system_log.vue'),
        meta: {
          name: '系统日志'
        }
      }
    ]
  }
]
