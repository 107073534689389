import Vue from 'vue'
import VueRouter from 'vue-router'

// 异步路由
import syncRoutes from './modules/syncRoutes'

// vuex
import Store from '@/store'

// 布局组件
const Layout = () => import('@/components/layout/index.vue')

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/welcome',
    component: Layout,
    children: [
      {
        path: '/welcome',
        component: () => import('@/views/welcome/welcome.vue'),
        meta: {
          name: '欢迎'
        }
      }
    ]
  },
  {
    path: '/edit_password',
    component: Layout,
    children: [
      {
        path: '/edit_password',
        component: () => import('@/views/edit_password/edit_password.vue'),
        meta: {
          name: '修改密码'
        }
      }
    ]
  },
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/redirect.vue')
      }
    ]
  }
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes
  })

const router = createRouter()

const whiteList = ['/', '/login']

router.beforeEach(async (to, from, next) => {
  // 动态更新标题
  document.title = to.meta.name ? '极风 - ' + to.meta.name : '极风管理系统'
  if (whiteList.indexOf(to.fullPath) !== -1) {
    next()
  } else {
    // 需要进行校验
    const token = await Store.dispatch('user/getToken')
    if (token) {
      const userInfo = Store.state.user.userInfo
      if (!userInfo) {
        // 没有用户信息
        const res = await Store.dispatch('user/getUserInfo')
        if (res.ret === 0) {
          // 登录成功无论如何都会重新添加可访问的侧边栏
          const allowRoutes = await Store.dispatch(
            'routes/getAllowRoutes',
            syncRoutes
          )
          router.matcher = createRouter().matcher
          router.addRoutes(allowRoutes)
          next({ ...to })
        } else {
          next({ path: '/login', replace: true })
        }
      } else {
        next()
      }
    } else {
      next({ path: '/login' })
    }
  }
})

export default router
