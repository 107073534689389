export default {
  namespaced: true,
  state: () => ({
    allowRoutes: []
  }),
  mutations: {
    // 添加可访问的路由
    addAllowRoutes(state, routes) {
      state.allowRoutes = routes
    }
  },
  actions: {
    // 获取可访问的路由, 正常情况下, 这里需要进行路由过滤
    // 空调比较特殊, 只有接口有操作权限
    getAllowRoutes({ commit, rootState }, routes) {
      return new Promise((resolve, reject) => {
        const level = rootState.user.level
        // 动态删除运营商
        if (level !== 100) {
          const index = routes.findIndex(i => i.path === '/operator')
          routes.splice(index, 1)
        }
        commit('addAllowRoutes', routes)
        resolve(routes)
      })
    }
  }
}
