<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      level: this.$store.state.user.level
    }
  }
}
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
}
</style>
