<template>
  <el-container class="el_container">
    <el-header class="el_header">
      <LayoutHeader />
    </el-header>
    <el-container class="inner_container">
      <el-aside width="248px" class="el_aside">
        <LayoutAside />
      </el-aside>
      <el-main class="el_main">
        <TagView />
        <div class="scroll_view">
          <el-scrollbar style="width: 100%; height: 100%" vertical>
            <keep-alive :include="cacheViews">
              <router-view></router-view>
            </keep-alive>
          </el-scrollbar>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LayoutHeader from './layout_header.vue'
import LayoutAside from './layout_aside.vue'
import TagView from '../tagView/tag_view'
import { mapState } from 'vuex'

export default {
  components: { LayoutHeader, LayoutAside, TagView },
  data() {
    return {}
  },
  methods: {
    // 添加路由
    addView() {
      this.$store.dispatch('tagViews/addView', this.$route)
    }
  },
  mounted() {
    this.addView()
  },
  computed: {
    ...mapState({
      cacheViews: state => state.tagViews.cacheView
    })
  },
  watch: {
    $route() {
      this.addView()
    }
  }
}
</script>

<style lang="scss" scoped>
.el_container {
  .el_header {
    height: 80px !important;
    background-color: #252b3b;
    padding: 0 !important;
  }

  .inner_container {
    height: calc(100vh - 80px);

    .el_aside {
      background-color: #252b3b;
    }

    .el_main {
      padding: 0 !important;
      background: #f9f8fd;

      .scroll_view {
        width: 100%;
        height: calc(100vh - 120px);
      }
    }
  }
}
</style>
<style lang="scss">
.scroll_view {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
