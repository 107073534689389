<template>
  <div>
    <div v-for="(item, index) in routes" :key="index">
      <!-- 没有子路由或者子路由只有一个的, 渲染下面的 -->
      <!-- 多加一个层级, 把带有 hidden = true 的路由过滤出去 -->
      <template v-if="!item.meta.hidden">
        <el-menu-item
          :index="item.children ? item.children[0].path : item.path"
          v-if="getChildrenShowLength(item) < 2"
        >
          <template>
            <i
              :class="(item.meta && item.meta.icon) || ''"
              style="margin-right: 10px"
            ></i>
            <span slot="title">{{
              item.children ? item.children[0].meta.name : item.meta.name
            }}</span>
          </template>
        </el-menu-item>

        <!-- 有子路由且子路由有多个的 -->
        <el-submenu :index="item.path" v-else>
          <template slot="title">
            <i
              :class="(item.meta && item.meta.icon) || ''"
              style="margin-right: 10px"
            ></i>
            <span slot="title">{{ item.meta.name }}</span>
          </template>
          <MenuItem :routes="item.children" />
        </el-submenu>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: ['routes'],
  data() {
    return {}
  },
  methods: {
    // 返回当前路由的子路由的显示个数,可能没有children属性
    getChildrenShowLength(rout) {
      if (rout.children) {
        let len = 0
        rout.children.map(i => {
          if (i.meta && !i.meta.hidden) {
            len++
          }
        })
        return len
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
