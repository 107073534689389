import Store from '../store'
import Vue from 'vue'

const supAdmin = {
  inserted(el, b, c) {
    if (Store.getters.level !== 100) {
      el.style = 'display:none!important'
    }
  }
}

Vue.directive('supAdmin', supAdmin)
