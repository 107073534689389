<template>
  <div class="tag_view" ref="tagView">
    <div
      class="scroll_tag_view"
      :style="
        'width: ' + getWidth + ';transform: translateX(' + translateX + 'px)'
      "
      @mousewheel.stop="handleScroll"
      ref="scrollTagView"
    >
      <router-link
        :to="{ path: item.path, query: item.query }"
        v-for="item in tagViews"
        :key="item.fullPath"
        :class="$route.path === item.path ? 'current' : ''"
        @contextmenu.prevent.native="menuClick($event, item)"
      >
        <span>{{
          item.meta.name.length > 5
            ? item.meta.name.substring(0, 5)
            : item.meta.name
        }}</span>
        <div class="close_icon" v-if="item.fullPath !== '/welcome'">
          <i
            class="el-icon-close"
            @click.stop.prevent="delCurrentView(item)"
          ></i>
        </div>
      </router-link>
    </div>

    <div class="tool_box" :style="toolStyle" ref="toolbox">
      <div class="item" @click="closeTag(1)">关闭当前</div>
      <div
        class="item"
        @click="closeTag(2)"
        v-if="currentRoute && currentRoute.fullPath === this.$route.fullPath"
      >
        刷新
      </div>
      <div class="item" @click="closeTag(3)">关闭其他</div>
      <div class="item" @click="closeTag(4)">关闭全部</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      translateX: 0,
      // 是否显示工具栏
      showTool: false,
      // 工具的样式
      toolStyle: {
        display: 'none',
        left: 0,
        top: 0
      },
      // 当前右键点击的标签页
      currentRoute: ''
    }
  },
  methods: {
    // 关闭当前的tagView
    delCurrentView(item) {
      if (item.path === '/welcome') return
      this.$store
        .dispatch('tagViews/delCurrentView', item)
        .then(({ tagViews }) => {
          if (item.fullPath === this.$route.fullPath) {
            // 关闭的是当前高亮的标签
            if (tagViews.length) {
              const path = tagViews[tagViews.length - 1].fullPath
              this.$router.push(path)
            } else {
              this.$router.push('/welcome')
            }
          }

          // 重新设置tagView的滚动位置
          this.resetScroll()
        })
    },

    // 重置滚动距离
    resetScroll() {
      // tagView的宽度
      const tagViewWidth = this.$refs.tagView.offsetWidth
      // 找到索引
      const cur = this.tagViews.findIndex(i => {
        return i.fullPath === this.$route.fullPath
      })
      const needMove = (cur + 1) * 120 - tagViewWidth
      this.translateX = needMove > 0 ? -needMove : 0
    },

    // 滚动事件
    handleScroll(ev) {
      // 滚轮上滚动是正120 下滚动是 -120
      const delta = ev.wheelDeltaY
      const tagViewWidth = this.$refs.tagView.offsetWidth
      const canScroll = this.tagViews.length * 120 - tagViewWidth
      if (canScroll <= 0) return
      if (delta > 0) {
        const newTranslateX = this.translateX + 20
        this.translateX = newTranslateX > 0 ? 0 : newTranslateX
      } else {
        const newTranslateX = this.translateX - 20
        this.translateX =
          newTranslateX < -canScroll ? -canScroll : newTranslateX
      }
    },

    // 添加事件监听
    addEventListen() {
      window.addEventListener('resize', this.resetScroll)
    },

    // 移除事件监听1
    removeEventListen() {
      window.removeEventListener('resize', this.resetScroll)
    },

    // 鼠标右键点击事件
    menuClick(ev, route) {
      if (route.fullPath === '/welcome') return
      this.currentRoute = route
      const x = window.innerWidth - ev.pageX
      let left = ev.pageX
      if (x <= 40) {
        left = left + x - 40
      }
      this.toolStyle = {
        display: 'block',
        top: `${ev.pageY}px`,
        left: `${left}px`
      }
      this.addClickListen()
    },

    // 全局监听鼠标左键事件, 关闭 menu tool
    addClickListen() {
      if (this.toolStyle.display === 'block') {
        document.body.addEventListener('click', () => {
          this.toolStyle.display = 'none'
        })
      }
    },

    // menu tool 选中操作 1.关闭当前 2.关闭其他 3.关闭所有
    closeTag(type) {
      if (type === 1) {
        this.delCurrentView(this.currentRoute)
      } else if (type === 2) {
        this.$store
          .dispatch('tagViews/redirectCurrent', this.currentRoute)
          .then(() => {
            const fullPath = this.currentRoute.fullPath
            this.$nextTick(() => {
              this.$router.replace({
                path: '/redirect' + fullPath
              })
            })
          })
      } else if (type === 3) {
        this.$store
          .dispatch('tagViews/delOthers', this.currentRoute)
          .then(res => {
            const path = res && res[0] ? res[0].fullPath : '/welcome'
            this.$router.push(path)
            this.resetScroll()
          })
      } else {
        // 关闭全部
        this.$store.dispatch('tagViews/delAll').then(() => {
          this.$router.push('/welcome')
        })
      }
    }
  },
  created() {
    this.addEventListen()
  },
  destroyed() {
    this.removeEventListen()
  },
  computed: {
    // 渲染tag的tagView
    tagViews() {
      return this.$store.state.tagViews.tagViews
    },

    // 计算宽度
    getWidth() {
      return this.tagViews.length * 120 + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.tag_view {
  width: calc(100vw - 248px);
  height: 40px;
  background-color: #1d222e;
  overflow: hidden;
  border-bottom: 1px solid #2e364a;

  .scroll_tag_view {
    height: 100%;
    display: flex;

    a {
      height: 100%;
      flex: 0 0 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: #ffffff;
      background-color: #1d222e;
      line-height: 1;
      &.current {
        // background-color: #0062ff;
        background-color: #f9f8fd;
        color: #222;
      }

      &:hover .close_icon {
        background-color: rgba(255, 255, 255, 0.4);
      }

      .close_icon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-left: 6px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 12px;
        }
      }
    }
  }
}

.tool_box {
  position: fixed;
  top: 100px;
  left: 100px;
  z-index: 100;
  background-color: #fff;
  width: 80px;
  border-radius: 4px;
  transform: translateX(-50%);
  overflow: hidden;
  padding: 8px 0;
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.05);

  .item {
    line-height: 2;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #eee;
    }
  }
}
</style>
