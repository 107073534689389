import Vue from 'vue'
import Vuex from 'vuex'

import routes from './modules/routes'
import tagViews from './modules/tagViews'
import user from './modules/user.js'
import getters from './getters.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    routes,
    tagViews,
    user
  },
  getters
})
