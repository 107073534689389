<template>
  <div class="header">
    <div class="logo">
      <img src="../../assets/index_logo.png" alt="" />
    </div>
    <div class="controller">
      <span class="welcome">你好，{{ getName || 管理员 }}</span>
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <img src="../../assets/USER.png" alt="" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <div class="version">v1.0.1</div>
            <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <Alert ref="alertRef" @confirm="logout" />
  </div>
</template>

<script>
import Alert from '@/components/alert/alert.vue'

export default {
  components: { Alert },
  data() {
    return {}
  },
  methods: {
    // 用户头像下拉框指令事件
    handleCommand(command) {
      if (command === 'logout') {
        this.$refs.alertRef.alert({
          title: '退出提醒',
          msg: '您确定要退出登录吗?'
        })
      } else if (command === 'editPassword') {
        this.editPassword()
      }
    },

    // 退出登录
    async logout() {
      /**
       * 1. 清除token
       * 2. 清除用户信息
       * 3. 重置路由(调用addRoutes之前会先先把一个崭新的router的matcher赋值给当前的router的matcher)
       * 4. 清除tagView
       * 5. 回到登录页面
       */
      this.$store.dispatch('user/logout').then(() => {
        this.$router.replace('/login')
      })
    },

    // 修改密码
    editPassword() {
      this.$router.push('/edit_password')
    }
  },
  computed: {
    getName() {
      return this.$store.state.user.userInfo.user_name
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .logo {
    width: 160px;
    height: 34px;
    margin-left: 35px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .controller {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .welcome {
      color: #fff;
    }

    .user {
      width: 51px;
      height: 51px;
      margin: 0 78px 0 32px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.version {
  height: 40px;
  text-align: center;
  line-height: 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
</style>
