import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 按需引入 elementUI
import './utils/elementUI.js'

// import 'element-ui/lib/theme-chalk/index.css'
import './styles/theme/index.css'

// 导入 纳米级进度条 样式
import 'nprogress/nprogress.css'

// 这里的代码需要放在elementUI的样式的后面, 以层叠掉elementUI的样式
import './styles/global.scss'

// 引入字体图标
import './fonts/style.css'

// 引入自定义指令
import './directive'

Vue.config.productionTip = false

// 使用elementUI组件
// Vue.use(ElementUI, { size: 'small', zIndex: 100 })
// Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
